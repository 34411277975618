import React from "react";

const HotTableGames = () => {
  return (
    <div>
      <section className="gameListSection">
        <div className="container">
          <div className="section-outer">
            <div class="title-wrapper">
              <div class="title-text">
                <h4 class="title">Hot Table Games!</h4>
                <h6>Tables hot and we have players winning big time</h6>
              </div>
              <a
                class="see-all"
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
              >
                See All <i class="material-icons">chevron_right</i>
              </a>
            </div>
            <div class="inner-section desktop">
              <div class="container-inner column-size-6 grid grid-cols-6">
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/1ydyxUze5vTYbrJqZpbeps/b14de6dbc3c54ba031cb91417946e2ed/OneTouch_BlackJackSupreme_Multihand_490x368.jpg"
                      class="bg"
                      alt="Boxing King"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Boxing King</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/6HzNHnqw4VXYnwb8UPweYX/bb23ec794e4fd6749a920b99f3ccb973/199-TongItsGo.jpg"
                      class="bg"
                      alt="Golden Empire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Golden Empire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/2LTbf6XZ34Ntg9Fy7hhgNF/67a22e8b7d26f83d315cee662a39f1cf/Baccarat-1.png"
                      class="bg"
                      alt="Fortune Gems"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Gems</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/5hCojPqNiMi2ht3XXURWQn/8eb8db59006cd9686e7078ad0503bc93/480x366-pusoy-go.jpg"
                      class="bg"
                      alt="Charge Buffalo"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Charge Buffalo</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/488jA881impBioP6grCddi/ece245010bcccd3d248b9dd1def80b10/European_Roulette.png"
                      class="bg"
                      alt="Aztec Fire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Aztec Fire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/47lK98PZvFKnDuQubFvGSS/af485f5347feca03494efe6077834a07/480x366-blackjack-21.jpg"
                      class="bg"
                      alt="Fortune Tree"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Tree</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotTableGames;
