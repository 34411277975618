import React from "react";

const FunandExciting = () => {
  return (
    <div>
      <section className="gameListSection">
        <div className="container">
          <div className="section-outer">
            <div class="title-wrapper">
              <div class="title-text">
                <h4 class="title">Fun and Exciting games!</h4>
                <h6>Arcade, Fishing, Mines and more...</h6>
              </div>
              <a
                class="see-all"
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
              >
                See All <i class="material-icons">chevron_right</i>
              </a>
            </div>
            <div class="inner-section desktop">
              <div class="container-inner column-size-6 grid grid-cols-6">
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/4RW7QeIGb8sxaAqd7I9WhP/b0dc8a1966bbf006ebc61c209e136adc/229-Mines.jpg"
                      class="bg"
                      alt="Boxing King"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Boxing King</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/1eRe460ggDvh43MTjE9xhd/0c9870b57211ab4e9893e9ec410993f5/197-ColorGames.jpg"
                      class="bg"
                      alt="Golden Empire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Golden Empire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/3orY4UFZvpHIVRPf45VwKY/ea800779ec99d5152db7caef8dcd7575/32-JackpotFishing.jpg"
                      class="bg"
                      alt="Fortune Gems"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Gems</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/4b0OCP7udkvn1cLMoqQq7W/7ce272321664e5844c9a80c582a42c1b/74-MegaFishing.jpg"
                      class="bg"
                      alt="Charge Buffalo"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Charge Buffalo</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/7TOLWPpKRsSbF97SmAycr/455086335a6c741a470e82f00645a22c/aviator.jpg"
                      class="bg"
                      alt="Aztec Fire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Aztec Fire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/6lcUIvNZFF5LT08n7AWmBb/7c2f2573dc9a0635c23fcffdcc43c9c8/DS-letsshoot.jpg"
                      class="bg"
                      alt="Fortune Tree"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Tree</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FunandExciting;
