import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
const Banner = () => {
  return (
    <div>
      <Swiper
        navigation={true}
        pagination={true}
        loop={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <div
            className="hero-carousel"
            style={{
              backgroundImage: `url("https://images.ctfassets.net/scslmvjv6lim/4V378QksSDwMyQCgXVHUnl/384cbb496590fa31e6d7cbff5c811b60/Bingo-BG.jpg")`,
            }}
          >
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-4 d-flex flex-column align-items-start justify-content-center">
                    <div class="text-wrapper ">
                      <div class="tagline mx-0 ">
                        <div class="title white-text">
                          Be a Big Winner with our HOT JACKPOT!
                        </div>
                        <div class="text white-text">
                          Start Playing e-BINGO Now!
                        </div>
                      </div>

                      <div class="banner-jackpot">
                        <span class="banner-jackpot-label">Hot Jackpot</span>

                        <span class="banner-jackpot-amount">
                          <div class="jackpot-counter-container">
                            <span class="jackpot-number-group ">
                              <span class="jackpot-number-container">
                                <span class="jackpot-amount">₱704 022</span>
                              </span>
                            </span>
                          </div>
                        </span>
                      </div>
                      <a
                        class="tagline btn btn-cta shadow w-25 m-0 d-flex align-items-center justify-content-center mt-4"
                        href="/en/?type=registration&amp;modal=user"
                      >
                        <span>Sign-up Now!</span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-8">
                    <img
                      src="/assets/banner/bannerR1.png"
                      alt="hero img"
                      srcset=""
                      className="panel-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
        <SwiperSlide>
          <div
            className="hero-carousel"
            style={{
              backgroundImage: `url("https://images.ctfassets.net/scslmvjv6lim/4V378QksSDwMyQCgXVHUnl/384cbb496590fa31e6d7cbff5c811b60/Bingo-BG.jpg")`,
            }}
          >
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 d-none d-lg-block">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                      <div class="text-wrapper ">
                        <div class="tagline mx-0 ">
                          <div class="title white-text">
                            Be a Big Winner with our HOT JACKPOT!
                          </div>
                          <div class="text white-text">
                            Start Playing e-BINGO Now!
                          </div>
                        </div>

                        <div class="banner-jackpot">
                          <span class="banner-jackpot-label">Hot Jackpot</span>

                          <span class="banner-jackpot-amount">
                            <div class="jackpot-counter-container">
                              <span class="jackpot-number-group ">
                                <span class="jackpot-number-container">
                                  <span class="jackpot-amount">₱704 022</span>
                                </span>
                              </span>
                            </div>
                          </span>
                        </div>
                        <a
                          class="tagline btn btn-cta shadow w-25 m-0 d-flex align-items-center justify-content-center mt-4"
                          href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                        >
                          <span>Sign-up Now!</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
                    <img
                      src="/assets/banner/bannerR1.png"
                      alt="hero img"
                      srcset=""
                      className="panel-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="hero-carousel"
            style={{
              backgroundImage: `url("https://images.ctfassets.net/scslmvjv6lim/5jqOk3x9ilVs3x47ugwez1/0a0ed6b5489585c8eaee17055ab76fa8/BG.jpg")`,
            }}
          >
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 d-none d-lg-block">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                      <div class="text-wrapper ">
                        <div class="tagline mx-0 ">
                          <div class="title white-text">Ivang Swerte To'</div>
                          <div class="text white-text">
                            Sign-up Now at Bet88!
                          </div>
                        </div>

                        <a
                          class="tagline btn btn-cta shadow w-25 m-0 d-flex align-items-center justify-content-center mt-4"
                          href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                        >
                          <span>Sign-up Now!</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/51fPPul8a71l221q7qDC0R/d07710f540f4766e047aca2014cb1c7c/ICON.png"
                      alt="hero img"
                      srcset=""
                      className="panel-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="hero-carousel"
            style={{
              backgroundImage: `url("https://images.ctfassets.net/scslmvjv6lim/1cxucuSUthPPzLmwc8gNsI/ee8550a1d4d7156b0e0ff8ae3859a435/BG-1.jpg")`,
            }}
          >
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 d-none d-lg-block">
                    <div className="d-flex flex-column align-items-start justify-content-center h-100">
                      <div class="text-wrapper ">
                        <div class="tagline mx-0 ">
                          <div class="title white-text">JILI is here!</div>
                        </div>

                        <a
                          class="tagline btn btn-cta shadow w-25 m-0 d-flex align-items-center justify-content-center mt-4"
                          href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                        >
                          <span>Play Now!</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/ZU2NgZ6OdkDd4jSvHkxPD/120be5401e7b397784c518d8982f4be3/ICON.png"
                      alt="hero img"
                      srcset=""
                      className="panel-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;
