import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { navbarData } from "../../data/navbar.data";
const Header = () => {
  return (
    <>
      <header className="d-side-menu py-2 ">
        <div className="container-fluid d-flex">
          <div className="desktop-top-menu-nav">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <GiHamburgerMenu className="text-white fs-4" />
            </button>
            {navbarData.map((item) => {
              return (
                <a
                  className="nav-link nav-link-menu ms-4 d-none d-lg-block text-uppercase d-block d-md-none d-xs-none"
                  href={item.link}
                >
                  {item.title}
                </a>
              );
            })}
          </div>

          <div class="desktop-logo-container">
            <a
              aria-current="page"
              class="linkCss active"
              href="https://bj88ph.live/af/Zd946zCP/bet88-live"
            >
              <img
                alt="logo"
                class="logo dark-logo"
                src="//images.ctfassets.net/scslmvjv6lim/38vC5BKc23NePKdAP9JVCu/8326ff50a710c151847dae7831e050a6/pagcor-bet88-logo-a-white-overlay.png"
              />
            </a>
          </div>

          <div class="desktop-user-panel d-block d-none d-xs-none d-sm-none d-lg-block">
            <div class="lngAndButtonWrap">
              <div class="language-menu-container">
                <div class="dropdown">
                  <div class="dropbtn">
                    <div class="dropdownItem active">
                      <div class="item active">
                        <a href="https://bj88ph.live/af/Zd946zCP/bet88-live">
                          <div class="flag-button-container">
                            <span class="flagIcon">
                              <i class="material-icons text-white">language</i>
                            </span>
                            <span class="arrow-icon">
                              <span class="material-icons text-white">
                                arrow_drop_down
                              </span>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-content">
                    <div class="dropdownItem">
                      <div class="item">
                        <span>
                          <span>EN</span> English
                        </span>
                      </div>
                    </div>
                    <div class="dropdownItem">
                      <div class="item">
                        <span>
                          <span>ZH</span> 中文
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                class="btn btn-secondary small desktop-login"
              >
                Login
              </a>
              <a
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                class="btn btn-cta small register-link ms-2"
              >
                Sign up
              </a>
            </div>
          </div>

          <div class="headerRight d-block d-lg-none">
            <div class="lngAndButtonWrap">
              <a
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                class="btn login-sm-btn small login-button text-white"
              >
                Login
              </a>
            </div>
          </div>
          {/* offcanvas */}
          <div
            class="offcanvas offcanvas-start d-side-menu"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-header d-flex justfiy-content-between w-100">
              <button
                class="material-symbols-outlined btn-close text-white"
                type="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                close
              </button>
              <div class="language-menu-container">
                <div class="dropdown">
                  <div class="dropbtn">
                    <div class="dropdownItem active">
                      <div class="item active">
                        <div class="flag-button-container">
                          <span class="flagIcon">
                            <span class="material-icons">language</span>
                          </span>
                          <span class="arrow-icon">
                            <span class="material-icons">arrow_drop_down</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dropdown-content">
                    <div class="dropdownItem">
                      <div class="item">
                        <span
                          className="ps-4"
                          // style="padding-left: 10px;"
                        >
                          <span
                            className="lang-icon "
                            // style="width: 29px; display: inline-block;"
                          >
                            EN
                          </span>{" "}
                          English
                        </span>
                      </div>
                    </div>
                    <div class="dropdownItem">
                      <div class="item">
                        <span
                        // style="padding-left: 10px;"
                        >
                          <span
                          //  style="width: 29px; display: inline-block;"
                          >
                            ZH
                          </span>{" "}
                          中文
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="side-menu-content">
              <div class="image"></div>
              <div class="text">
                <div class="title">JOIN US!</div>
                <div class="tagline">The #1 Casino</div>
              </div>
            </div>

            <div class="offcanvas-body">
              <div class="logged-out-items">
                <a
                  class="btn btn-cta full-width register-link"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  Sign up
                </a>
                <a
                  class="btn btn-secondary-popup full-width"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  Login
                </a>
              </div>
              <div class="menu-items">
                <a
                  class="nav-link CUSTOM e-bingo"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  E-BINGO
                </a>
                <a
                  class="nav-link CASINO "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  Casino
                </a>
                <a
                  class="nav-link CUSTOM live-dealer"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  LIVE DEALER
                </a>
                <a
                  class="nav-link SPORTSBOOK "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  Sports
                </a>
                <a
                  class="nav-link CUSTOM arcade"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  ARCADE
                </a>
                <a
                  class="nav-link CUSTOM agents"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  AGENTS
                </a>
                <a
                  class="nav-link CUSTOM promotions"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  PROMOTIONS
                </a>
                <a
                  class="nav-link CUSTOM vip"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  VIP
                </a>
                <a
                  class="nav-link CUSTOM ivang-swerte!"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  IVANG SWERTE!
                </a>
              </div>

              <div class="footer-items">
                <a
                  class="nav-link text-link"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  F.A.Q
                </a>
                <a
                  class="nav-link "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  News
                </a>
                <a
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  class="nav-link"
                >
                  Customer Support
                </a>
                <a
                  aria-current="page"
                  class="nav-link download-app-image active"
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="tagline download-app-image">
                    <img
                      title="Download-App-EN"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/2bY4HH1EpcDZ88GWFpZ4jb/412e9678908ef32dda51ea85f11bcc09/Download-App-EN.png"
                    />
                    <span></span>
                  </div>
                </a>

                <div class="app-install-container">
                  <div class="app-buttons-container">
                    <div class="download-text-area">Downlaod App</div>
                    <button
                      name="android app download button"
                      aria-label="android app download button"
                      class="app-button android"
                    >
                      <i class="device-icon">
                        <img
                          src="https://cdn.bet88.ph/static/media/android.58e17624.svg"
                          alt="android"
                        />
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
