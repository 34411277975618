import React from "react";

const Announcement = () => {
  return (
    <div>
      <div class="styled-text-list announcement">
        <div class="containers">
          <div class="rows">
            <div class="tagline announcement-icon">
              <img
                title="Announcement icon"
                alt="info"
                src="//images.ctfassets.net/scslmvjv6lim/OF1lF3nDKZKY2259lbmQa/af118f3cbeff2033175ebdd31ab7e37c/annon.svg"
              />
              <span></span>
            </div>
            <div class="tagline announcement-text">
              <div className="marquee-container">
                <div className="marquee ">
                  <marquee direction="left">
                    Ivang Swerte dito sa Bet88!” Sign-up now and enjoy fast and
                    secure transactions!
                  </marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Announcement;
