import React from 'react'
import Banner from '../component/Carousel/Banner'
import Gamelist from '../component/Home/section/Gamelist'
import Favorite from '../component/Home/section/Favorite'
import Jackpot from '../component/Home/section/Jackpot'
import FunandExciting from '../component/Home/section/FunandExciting'
import HotTableGames from '../component/Home/section/HotTable'
import Cards from '../component/Home/section/Cards'
import JackpotWinners from '../component/Home/section/JackpotWinners'
import CasinoWinners from '../component/Home/section/CasinoWinner'
import BestPractice from '../component/Home/section/BestPractice'

const Home = () => {
  return (
    <main>
      <Banner/>
      <Gamelist/>
      <Favorite/>
      <Jackpot/>
      <FunandExciting/>
      <HotTableGames/>
      <Cards/>
      <JackpotWinners/>
      <CasinoWinners/>
      <BestPractice/>
    </main>
  )
}

export default Home

