import React from "react";

const Gamelist = () => {
  return (
    <div>
      <section className="gameListSection">
        <div className="container">
          <div className="section-outer">
            <div class="title-wrapper">
              <div class="title-text">
                <h4 class="title">Hot Games!</h4>
                <h6>Start spinning and win the Jackpot</h6>
              </div>
              <a
                class="see-all"
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
              >
                See All <i class="material-icons">chevron_right</i>
              </a>
            </div>
            <div class="inner-section desktop">
              <div class="container-inner column-size-6 grid grid-cols-6">
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/7qawi3YTqqUzdYyL5pNA24/b2230810b82c4885a1efd93ecfe7dd02/49-SuperAce.jpg"
                      class="bg"
                      alt="SuperAce"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">SuperAce</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/3MEvb9sftGHfOJVne7OHKv/1c1e30b9c479c21c47ad29d37c794362/480x366-wild-ace.jpg"
                      class="bg"
                      alt="Wild Ace"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Wild Ace</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/6bZGc2MOwYye1lF60dK2w7/d4f8951041b7553834fdcc809433913e/480x366-boxing-king.jpg"
                      class="bg"
                      alt="Boxing King"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Boxing King</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/1YbziqzNTXrTwgmbO5OIZf/1348de3e49c9bd3fa0f566efcfba2da9/480x366-golden-empire-min.jpg"
                      class="bg"
                      alt="Golden Empire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Golden Empire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/3x2uOQ29DtCnTOvbNsPBjS/795b58f0eb19ed7ff3e2e1a2d4ff0912/480x366-fortune-gems.jpg"
                      class="bg"
                      alt="Fortune Gems"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Gems</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/4WlZPpFOK3Eh2kUcVUG28m/5f513d3e33c2af4fa652570f5e5898ed/ChargeBuffalo.jpg"
                      class="bg"
                      alt="Charge Buffalo"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Charge Buffalo</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/29EOgOXccYSlXDulRmG3gK/5962053aa8179aa1107aa0b61484b635/480x366-Aztec-Fire.png"
                      class="bg"
                      alt="Aztec Fire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Aztec Fire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/5WujO0HAg8TrgmHYwAwLwJ/09005b6456c8a6270b6d347ddf092d2f/FortuneTree.jpg"
                      class="bg"
                      alt="Fortune Tree"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Tree</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/76q5YAlGoBLGhDrFVIzWFx/1913f2e092681de180ec49ed873c4457/480x366-coin-volcano.jpg"
                      class="bg"
                      alt="Coin Volcano"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Coin Volcano</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/1PIL6ebClwGbCyslXZlcvC/d73280c13b65e4c99e8d917df6696a7a/getmoney.jpg"
                      class="bg"
                      alt="Get Money"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Get Money</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/5E7Ea4TefkaktLfrtnhaGa/84a33454c0b8e468e6183fcc5aa46363/480x366-roll-in-money-min.jpg"
                      class="bg"
                      alt="Roll in Money"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Roll in Money</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="//images.ctfassets.net/scslmvjv6lim/hAC3zFZ26OEg8W1Y9WDH4/6833429578f168d768fce9a593d93b63/480x366-money-coming-min.jpg"
                      class="bg"
                      alt="Money Coming"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Money Coming</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gamelist;
