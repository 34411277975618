import React from "react";

const Favorite = () => {
  return (
    <div>
      <section className="gameListSection">
        <div className="container">
          <div className="section-outer">
            <div class="title-wrapper">
              <div class="title-text">
                <h4 class="title">Our Favorite Live Casino!</h4>
                <h6>Place your bets while the table's hot!</h6>
              </div>
              <a
                class="see-all"
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
              >
                See All <i class="material-icons">chevron_right</i>
              </a>
            </div>
            <div class="inner-section desktop">
              <div class="container-inner column-size-6 grid grid-cols-6">
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/7tQMB5ST2EhY8e5hsj5csK/7e393eed8200c0afac8e9cd19cd7ffbd/Golden_Wealth_Baccarat.jpg"
                      class="bg"
                      alt="Boxing King"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Boxing King</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/1o0FDhQHTLxZvipxdiSBjf/5beb1881d85fa8635f804facad13c98c/EVO-lightningtable01.jpg"
                      class="bg"
                      alt="Golden Empire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Golden Empire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/GD8Mo4Fkyq5mh334Ddx2C/f70247cdbc5b3f6caa254f4eff9e5e1d/EVO-blackjack.jpg"
                      class="bg"
                      alt="Fortune Gems"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Gems</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/3ZWruBRO76S9D7dqZBC86V/7918eefb6ca706d7842a98dc1675f512/480x366-crazy-time.jpg"
                      class="bg"
                      alt="Charge Buffalo"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Charge Buffalo</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/3y0xRFJxsJX2IDItcM3CVD/1b998709aa7b34a9cc8e0afe77ce2602/lightningbaccarat.jpg"
                      class="bg"
                      alt="Aztec Fire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Aztec Fire</p>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/1GpfvNoWF7sYFZne56OgZD/597927f6304181bb61a0e306a0e30317/sagaming-sexybaccarat-box-logo.webp"
                      class="bg"
                      alt="Fortune Tree"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Tree</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Favorite;
