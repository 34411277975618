import React from "react";

const JackpotWinners = () => {
  return (
    <div>
      <section className="mt-5">
        <div className="container">
          <div class="tagline winner">
            <img
              title="HotJackpot-v2"
              alt="info"
              src="//images.ctfassets.net/scslmvjv6lim/14l1j53AcCa8qKqVfYvE3T/b9e0d33bcc0a0947fe1b0ced34af5d26/350x50-EN.png"
            />
            <span></span>
          </div>
          <div className="container">
            <div className="">
              <div class="taglines-group row">
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex  justify-content-between group top-winner-list-3 top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon"
                  >
                    <img
                      title="GoldenLion"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/6W8CA9xEcXg0axJ3O1VHgg/d170fcdb3bbbbd0ed4a41f0d1b636fac/70x70-Goldenlion20.jpg"
                      // className="w-100"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Golden Lion</h4>
                    <span>User: 6JJJJ9P</span>
                    <span>10-05-2023 20:25:57</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱140,696</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex  justify-content-between group top-winner-list-2 top-winner">
                  <a
                    class="tagline game-icon"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <img
                      title="lamina20"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/5uPm8wzmQF6C7sZNgXtzE0/b212701f6e5331253af568edc6980312/lamina20.png"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>La Mina20</h4>
                    <span>User: PJMGG6</span>
                    <span>10-09-2023 00:28:49</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱111,636</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex  justify-content-between group top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon"
                  >
                    <img
                      title="Multidragon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/23qoyx9J06v7Su977btflc/5b3f5f224c54e0c000287742466cfc24/Multidragon.png"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Multi Dragon </h4>
                    <span>User: LMJQM39</span>
                    <span>10-08-2023 02:58:01</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱43,597</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JackpotWinners;
