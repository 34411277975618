export const navbarData = [
    {
        title: 'e-bingo',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
    {
        title: 'casino',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
    {
        title: 'live dealer',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
    {
        title: 'sports',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
    {
        title: 'arcade',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
    {
        title: 'agents',
        link: 'https://bj88ph.live/af/Zd946zCP/bet88-live'
    },
]