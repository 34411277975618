export const HeroData = [
  {
    title: "Sports",
    subtitle: "Get in the Game! Bet and Win Big!",
    button: "Bet Now!",
    image:
      "https://images.ctfassets.net/scslmvjv6lim/6Y2bw1riW8YuoDwVkiIDTZ/6c853e3d876fd3ceb7bd9c02061e96d1/SPORTSBOOK-front-page-icon.png",
  },
  {
    title: "Download Bet88 APP",
    subtitle:
      "Experience faster gameplay and never miss out on exclusive promotions, latest updates, and more!",
    button: "!Download Now",
    image:
      "https://images.ctfassets.net/scslmvjv6lim/3DMOQ37MSpy8BuH3jybKFt/12166957d1eb543d85074bc0d86830c8/Bet88-APP.png",
  },
  {
    title: "Promotions",
    subtitle:
      "Check out our bonuses and rewards, and other limited time promotional offers to make your gaming experience even better!",
    button: "See All Promotions!",
    image:
      "https://images.ctfassets.net/scslmvjv6lim/3EpRuDzjAykDdcIQIQDW11/7d3e58f1c6d98d4c63f5d9a88f46dba0/ivana-100-super-ace.png",
  },
  {
    title: "Affiliate",
    subtitle:
      "Work with the best Philippine gaming company at your convenience, from home, with your laptop or mobile. Bring the eager punters and enjoy your financial freedom.",
    button: "!Register as an Agent",
    image:
      "https://images.ctfassets.net/scslmvjv6lim/1E4kw9W6cfzO23biBwrTej/8c9324c2bd236c30032369bd59cb9e34/Affiliates.jpg",
  },
];
