import React from "react";

const Jackpot = () => {
  return (
    <div>
      <section className="gameListSection">
        <div className="container">
          <div className="section-outer">
            <div class="title-wrapper">
              <div class="title-text">
                <h4 class="title">
                  Play as low as 0.50 cents per Bingo Card and still WIN the
                  Jackpot!
                </h4>
                <h6>First e-BINGO in the Philippines!</h6>
              </div>
              <a
                class="see-all"
                href="https://bj88ph.live/af/Zd946zCP/bet88-live"
              >
                See All <i class="material-icons">chevron_right</i>
              </a>
            </div>
            <div class="inner-section desktop">
              <div class="container-inner column-size-6 grid grid-cols-6">
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/T6OJuGFgqINZVnnDhLTmd/ddf96dbb610e6d3cc7bad079d92e36eb/480x366-1800sadventure.jpg"
                      class="bg"
                      alt="Boxing King"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Boxing King</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/tUrwcjyemESq4F6Qcny9E/c19ab8b9282cc0ee3336bf23972ab84f/480x366-Goldenlion20.jpg"
                      class="bg"
                      alt="Golden Empire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Golden Empire</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/VJr2PNcvoB9tcPRYIqPkl/1e4e8c57f983c1897b276b571e1c9b1f/Binvaders.jpg"
                      class="bg"
                      alt="Fortune Gems"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Gems</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/7gIhKHRjLp2rOwMDbzglIF/ee1e4794a9ca319e32bb0284c63de646/Lamina-20.jpg"
                      class="bg"
                      alt="Charge Buffalo"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Charge Buffalo</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/MR3A8F9x7RsNGeqmq2TWs/8f3fc3d1bef6ed57737a1593bfca9719/Grannational-20.jpg"
                      class="bg"
                      alt="Aztec Fire"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Aztec Fire</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
                <a
                  class="card-wrapper "
                  href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                >
                  <div class="card-game-list">
                    <img
                      src="https://images.ctfassets.net/scslmvjv6lim/4iqPOBonLJAzUUe5VEtzoa/071cbeed6f940d009fbf2151c59e7827/El-Pesero.jpg"
                      class="bg"
                      alt="Fortune Tree"
                    />
                    <div class="card-inner"></div>
                  </div>
                  <div class="card-game-name">
                    <p class="name">Fortune Tree</p>
                  </div>
                  <div class="jackpot-container">
                    <div class="jackpot-amount">₱705 706</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Jackpot;
