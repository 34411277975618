import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="p-5">
        <div className="container">
          <div className="appIconWrap d-flex justify-content-center">
            <div className="footer-row">
              <button
                className="android app download button app-button"
                onClick={() =>
                  (window.location.href =
                    "https://bj88ph.live/af/Zd946zCP/bet88-live")
                }
              >
                <i class="device-icon">
                  <img
                    src="https://cdn.bet88.ph/static/media/android.58e17624.svg"
                    alt="android"
                  />
                </i>
              </button>
            </div>
          </div>

          <div class="linkWrap pb-5"></div>
          <div className="row">
            <div className="container">
              <div className="taglines-group">
                <div class="group footer-game-column">
                  <div class="tagline title">
                    <span>CASINO</span>
                  </div>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Slots</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Table Games</span>
                  </a>
                  <div class="tagline title">
                    <span>LIVE DEALER</span>
                  </div>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Live Dealer</span>
                    <span></span>
                  </a>
                  <div class="tagline title">
                    <span>BINGO</span>
                  </div>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline text-link"
                  >
                    <span>e-Bingo</span>
                  </a>
                  <div class="tagline title">
                    <span>SPORTS</span>
                  </div>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Sports</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Live Betting</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Sportsbook Rules</span>
                  </a>
                </div>

                <div class="group footer-about-column">
                  <div class="tagline title">
                    <span>ABOUT US</span>
                  </div>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline text-link"
                  >
                    <span>Promotion Terms and Conditions</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Responsible Gaming</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>About Us</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>F.A.Q</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Contact Us</span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline text-link"
                  >
                    <span>Agent</span>
                  </a>
                  <a
                    class="tagline "
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>News</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Terms of Use</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Privacy Policy</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Cookie Policy</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>General Terms &amp; Conditions</span>
                  </a>
                  <div class="tagline download-app-image">
                    <img
                      title="Download-App-EN"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/2bY4HH1EpcDZ88GWFpZ4jb/412e9678908ef32dda51ea85f11bcc09/Download-App-EN.png"
                    />
                    <span></span>
                  </div>
                </div>
                <div class="group footer-payments">
                  <div class="tagline ">
                    <img
                      title="payments"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/LepLsxrWMn2ezSiaNYG9y/a7d069927a362780b24df3747e033bfa/Bet88-footer-2.png"
                      className="w-100"
                    />
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-dynamic footer-follow-us-section ">
          <div class="container">
            <div class="row">
              <div class="col-md-6 taglines"></div>
              <div class="col-md-6"></div>
              <div class="col-md-12 taglines-group middle">
                <div class="group follow-us">
                  <div class="tagline title">
                    <span>Follow us</span>
                  </div>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="facebook-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/79n0MzQjgBaXsGLHaTlyEF/b9ffc677841237580afc370dda821e05/facebook-icon.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="twitter-x"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/4rOvmSGtAWXyqk6Mg6eQCf/99e0988582c21b4d0d76b6460c3e5293/Twitter-X-Logo.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="insta-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/1GyL46BoVaT6mjmJD6GkHS/6d3eef6b5d2a6c87e0dc10ae21cfee25/insta-icon.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="youtube-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/VwXgmlLapgNADuyOQFfEK/dd39443bf495a85e12301d006bc3b054/youtube-icon.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="telegram-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/6XCsZJINbY762TkZSlVRxP/2f313731667695fe807ad0ad591f6df1/telegram-icon.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="discord-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/6Xs6BL59jcjRhy8qTGluOF/8e1491981c38c74e9ae1f045ce66106a/discord-icon.svg"
                    />
                    <span></span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline "
                  >
                    <img
                      title="tiktok-icon"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/qFq7magWiRjul3hJdKb14/6cebe31331c52f26d7ca4110a0d6bf22/tiktok-icon.svg"
                    />
                    <span></span>
                  </a>
                </div>
                <div class="group age">
                  <div class="tagline ">
                    <img title="21" alt="info" src="/assets/img/info-8.svg" />
                    <span></span>
                  </div>
                </div>
                <div class="group licensed">
                  <div class="tagline title">
                    <span>Licensed by</span>
                  </div>
                  <div class="tagline ">
                    <img
                      title="Bet88-PAGCOR-white-svg"
                      alt="info"
                      src="//images.ctfassets.net/scslmvjv6lim/7oHm7CwTgMWNuEZqFnfaGv/470724379abaf153480da0756499f296/white-for-bet88-site.svg"
                    />
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-dynamic footer-text-section ">
          <div class="container">
            <div class="col-md-6 taglines"></div>
            <div class="col-md-6"></div>
            <div class="col-md-12 taglines-group tagline-bottom">
              <div class="group text">
                <div class="tagline ">
                  <span>
                    The website is operated and managed by Bet88. Gaming
                    services on this website are provided by Bet88, licensed and
                    regulated by the Philippine Amusement and Gaming Corporation
                    (PAGCOR).
                  </span>
                  <span></span>
                  <span>
                    Gambling can be addictive. Keep it fun. Game responsibly.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-dynamic bottom-section ">
          <div class="container">
            <div class="row">
              <div class="col-md-6 taglines"></div>
              <div class="col-md-6"></div>
              <div class="col-md-12 taglines-group">
                <div class="group all-rights">
                  <div class="tagline ">
                    <span>© 2023 Bet88. All rights reserved.</span>
                  </div>
                </div>
                <div class="group bottom-link">
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Cookie Policy</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Terms of Use</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Privacy Policy</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Responsible Gaming</span>
                  </a>
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline text-link"
                  >
                    <span>Agent</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>Contact Us</span>
                  </a>
                  <a
                    class="tagline text-link"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <span>General Terms &amp; Conditions</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
