import React from "react";

const CasinoWinners = () => {
  return (
    <div>
      <section className="my-5">
        <div className="container">
          <div class="tagline winner">
            <span className="top-casino">Top Casino Winners</span>
          </div>
          <div className="container">
            {/* <div className="">
              <div class="col-md-12 taglines-group">
                <div class="group top-winner-list-3 top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon"
                  >
                    <img
                      title="GoldenLion"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/7qeoZVMNQTUZeZ6YBwrP9D/476dfbe204c348694a0004f06a1bac87/RLX-moneytrain3-70x70.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Money Train 3</h4>
                    <span>User: LMNG3X9</span>
                    <span>10-10-2023 03:36:08</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱3,377,000</span>
                  </div>
                </div>
                <div class="group top-winner-list-2 top-winner">
                  <a
                    class="tagline game-icon"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <img
                      title="lamina20"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/29Ey5xIv9PZXDJdLCDlP4H/89df57b6264ac794a6c71f1aa8d32d58/HAK-magicpiggy-70x70.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Magic Piggy</h4>
                    <span>User: LMNG3X9</span>
                    <span>10-10-2023 00:53:27</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱2,535,000</span>
                  </div>
                </div>
                <div class="group top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon"
                  >
                    <img
                      title="Multidragon"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/4xBRg4InulK8awWiT6adqR/49dc1717b6b2e7c24ff104feb8a11dd0/boomboomgold.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Boom Boom Gold</h4>
                    <span>User: GGJM3GJ</span>
                    <span>10-10-2023 18:24:16</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱ 367,006</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="">
              <div class="taglines-group row">
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-between  group top-winner-list-3 top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon"
                  >
                    <img
                      title="GoldenLion"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/7qeoZVMNQTUZeZ6YBwrP9D/476dfbe204c348694a0004f06a1bac87/RLX-moneytrain3-70x70.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Money Train 3</h4>
                    <span>User: LMNG3X9</span>
                    <span>10-10-2023 03:36:08</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱3,377,000</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex  justify-content-between  group top-winner-list-2 top-winner">
                  <a
                    class="tagline game-icon"
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                  >
                    <img
                      title="lamina20"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/29Ey5xIv9PZXDJdLCDlP4H/89df57b6264ac794a6c71f1aa8d32d58/HAK-magicpiggy-70x70.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Magic Piggy</h4>
                    <span>User: LMNG3X9</span>
                    <span>10-10-2023 00:53:27</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱2,535,000</span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-between group top-winner">
                  <a
                    href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                    class="tagline game-icon d-flex "
                  >
                    <img
                      title="Multidragon"
                      alt="info"
                      src="https://images.ctfassets.net/scslmvjv6lim/4xBRg4InulK8awWiT6adqR/49dc1717b6b2e7c24ff104feb8a11dd0/boomboomgold.jpg"
                    />
                    <span></span>
                  </a>
                  <div class="tagline winner-detail">
                    <h4>Boom Boom Gold</h4>
                    <span>User: GGJM3GJ</span>
                    <span>10-10-2023 18:24:16</span>
                  </div>
                  <div class="tagline win-price">
                    <span>Won!</span>
                    <span>₱ 367,006</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CasinoWinners;
