import React from "react";
import { HeroData } from "../../../data/section.data";

const Cards = () => {
  return (
    <div>
      <section>
        <div className="container">
          {HeroData.map((item) => {
            return (
              <div className="row per-row" key={item}>
                <div className="col-lg-6 taglines">
                  <div className="h-100 d-flex flex-column align-items-start justify-content-center">
                    <div class="tagline title">
                      <span>{item.title}</span>
                      <div class="tagline text">
                        <span>{item.subtitle}</span>
                      </div>
                      {/* <a
                        class="tagline btn btn-cta see-all-promotions shadow w-25 d-flex align-items-center justify-content-center mt-4"
                        href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                      >
                        <span>{item.button}</span>
                      </a> */}
                      <a
                        href="https://bj88ph.live/af/Zd946zCP/bet88-live"
                        class="tagline btn btn-cta register-agent shadow d-flex justify-content-center align-items-center"
                      >
                        <span>{item.button}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <img
                    src={item.image}
                    alt="game bet now"
                    srcset=""
                    className="w-100"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Cards;
