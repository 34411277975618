import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./component/Layout/Layout";
import Home from "./pages/Home";

import "../src/styles/header.css";
import "../src/styles/banner.css";
import "../src/styles/home.css";
import "../src/styles/footer.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="/about-us" element={<Home/>} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
